/* eslint-disable no-magic-numbers */
// 皮肤六
import React, { useCallback, useState, useEffect } from 'react'
import styles from './skin6.module.scss'
import classNames from 'classnames'
import { Input, Toast } from 'antd-mobile'
import { parseNum, isPhone, Storage } from '@bihu/common-js'
import BigNumber from 'bignumber.js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { TOKEN, ENTER_AGREEMENT, CURRENT_SKIN_INDEX } from '@/constants/storage'
import NumberInput from '@/components/number-input/number-input'
import activityApi from '@/apis/activity'
import { useSearchParams } from 'react-router-dom'
interface Props {
  callBack: () => void,
  type?: 'getQuota', // 领取额度
  channelDetail: any // 渠道详情
}

const Skin6: React.FC<Props> = props => {
  const { callBack, type, channelDetail } = props
  const hostNameObj = getHostNameObj()

  const [search] = useSearchParams()

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')
  const [is360Channel, setIs360Channel] = useState(false)

  useEffect(() => {
    const code = search.get('adChannelCode')
    const hasToken = Storage.get(TOKEN)
    if (code) {
      setAdChannelCode(code)
      localStorage.setItem('ad_channel_code', code)
      if (code === 'hBSfjw' || code === 'ApmVIb') {
        setIs360Channel(true)
      } else {
        setIs360Channel(false)
      }

      // 是否需要获取定位
      if (hasToken && channelDetail.location) {
        loginAfterLocation()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 调起位置授权
  const loginAfterLocation = () => {
    let isHandleCopyFunction = true
    let hasHandleCopyFunction = false
    // 开始执行位置授权
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // 获取到的地理位置 position
          isHandleCopyFunction = false
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(position.coords.latitude, position.coords.longitude)
        },
        error => {
          // 获取位置失败
          isHandleCopyFunction = false
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(null, null)
        },
      )
      setTimeout(() => {
        // 执行备用方案
        if (isHandleCopyFunction) {
          hasHandleCopyFunction = true
          getUserLocation(null, null)
        }
      }, 8000)
    } else {
      getUserLocation(null, null)
    }
  }
  const getUserLocation = async(latitude:any, longitude:any) => {
    let params = latitude ? {
      location: `${latitude},${longitude}`,
      adChannelCode: channelDetail.adChannelCode,
      coordtype: 'wgs84ll',
    } : {
      adChannelCode: channelDetail.adChannelCode,
    }
    const res:any = await activityApi.reverseGeo(params)
    // 配置白名单城市
    console.log('city', res.data)
    if (res.data && res.data.excludeCity && channelDetail.linkType === 2) {
      await activityApi.reportDevice({
        provinceName: res.data.province,
        cityName: res.data.city,
      })
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({
        isNotFormReq: true,
        isSpecialListCity: true
      }))}`
    } else {
      // 如果不在白名单，报告父组件，执行正常跳转的逻辑
      callBack()
    }
  }

  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')

  // 是否显示验证码弹窗
  const [showPopup, setShowPopup] = useState('')

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 是否抖动
  const [isShake, setIsShake] = useState(false)
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 最小金额1万
  const [min] = useState(1000)
  // 最大金额5万
  let [max] = useState(50000)

  // 借款金额
  const [amount, setAmount] = useState<string>('')

  // 当前选中期数
  const [currentPeriods, setCurrentPeriods] = useState<number>(12)

  // 借款金额改变
  const amountOnChange = (str: string) => {

    str = str.replace(/[^\d]/g, '')
    let newValue:number | string = str
    if (+str > max) {
      // newValue = max
    }
    newValue = parseNum(newValue as unknown as number)
    if (newValue && newValue !== '0') {
      setAmount(newValue)
    } else {
      setAmount('')
    }
  }

  // 失去焦点
  const amountOnBlur = () => {
    let str = amount.replace(/[^\d]/g, '')
    max = is360Channel ? 200000 : 50000
    if (+str > max) {
      setAmount(parseNum(max))
      return Toast.show(`您可借最大金额为${is360Channel ? '200000' : '50000'}元`)
    }
    if (+str < min) {
      setAmount(parseNum(min))
      return Toast.show('最低可借金额为1000元起')
    }
  }

  // 月利率
  const [monthlyInterestRate] = useState(0.0056)

  // 每月需还金额 = （借款金额 + 总利息）/ 期数
  const monthRepayAmountFn = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '0'
    }

    let val = new BigNumber(amountNew).plus(totalInterest(currentMonth))
      .div(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 总利息 = 借款金额*月利率*期数
  const totalInterest = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '-'
    }

    let val = new BigNumber(amountNew).multipliedBy(monthlyInterestRate)
      .multipliedBy(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    window.location.href = `/${path}-agreement?isShowHeader=true`
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // 获取验证码
  const getCode = async() => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.smsSend({
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }

    // Toast.show('获取验证码成功')
  }

  const openPopup = () => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    if (!checked) {
      setIsShake(true)
      setTimeout(() => {
        setIsShake(false)
      }, 100)
      return Toast.show('请勾选注册、隐私及个人信息共享授权协议')
    }
    if (!time) {
      getCode()
    }
    setShowPopup('true')
  }

  // 立即注册
  const register = async() => {
    if (!code) {
      return Toast.show('请输入验证码')
    }
    try {
      Toast.show({
        icon: 'loading',
        content: '领取中…',
        duration: 0,
      })
      const abTestType = 6
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await activityApi.login({
        mobile: phone,
        code,
        adChannelCode,
        abTestType,
        isFormReq: (type === 'getQuota' && adChannelCode !== 'YxNj9v'),
      })
      Storage.set(TOKEN, res.token)
      if (channelDetail.location) {
        loginAfterLocation()
      } else {
        callBack()
      }
    } catch (error:any) {
      Toast.clear()
      Toast.show(error.msg)
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  return (
    <div className={styles.skin6}>
      <header className={styles.header}>
        <div className={styles.logoBox}>
          <img className={styles.logo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
          <div className={styles.logoBoxRight}>
            <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
            <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle3.png')} alt="正规品牌 真实可信" />
          </div>
        </div>
        <div className={styles.headTitps}>
          <img className={styles.headTitpsImg} src={require('@imgs/register/new-theme/head-tips.png')} alt="" />
        </div>
        <div className={styles.main}>
          <div className={styles.limitTitle}>最高额度（元）</div>
          <div className={styles.limitNum}>200,000</div>
          <div className={styles.limitTips}>年化利率最低7.2%起，实际请以最终审核为准</div>
          <div className={styles.formBox}>
            <div className={styles.phoneBox}>
              <div className={styles.label}>手机号</div>
              <NumberInput
                placeholder="输入手机号"
                value={phone}
                numberFormat={{
                  decimal: false,
                  negative: false,
                  leadingZero: false,
                  maxLength: 11
                }}
                onChange={val => {
                  setPhone(val)
                }}
              />
            </div>
            <div className={classNames(styles.agreement, isShake && styles.animation)}>
              <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
              阅读并同意
              <span
                className={styles.primary}
                onClick={() => {
                  openView('register')
                }}
              >
              《用户注册服务协议》
              </span>
            、
              <span className={styles.primary} onClick={() => {
                openView('privacy')
              }}>《隐私政策》</span>
            和
              <span className={styles.primary} onClick={() => {
                openView('information')
              }}>《个人信息共享授权协议》</span>
            </div>
            <div className={styles.getCms} onClick={openPopup}>测一测我的额度</div>
          </div>
        </div>
      </header>
      <div className={styles.footerBar}>
        <img className={styles.footerBarImg} src={require('@imgs/register/new-theme/footer-bar-title.png')} alt="" />
        <div className={styles.steps}>
          <div className={styles.stepItem}>
            <div className={styles.stepNum}>1</div>
            <div className={styles.stepName}>加密注册</div>
          </div>
          <div className={styles.splitLine}></div>
          <div className={styles.stepItem}>
            <div className={styles.stepNum}>2</div>
            <div className={styles.stepName}>填写资料</div>
          </div>
          <div className={styles.splitLine}></div>
          <div className={styles.stepItem}>
            <div className={styles.stepNum}>3</div>
            <div className={styles.stepName}>审批贷款</div>
          </div>
        </div>
      </div>
      <div className={styles.tipBox}>
        <div className={styles.tip}>本平台是信息服务平台，不提供放贷业务</div>
        <div className={styles.tip}>平台向您展示的贷款产品或贷款咨询服务是由贷款服务机构</div>
        <div className={styles.tip}>(泛指为您提供贷款咨询服务或贷款发放服务的机构)</div>
        <div className={styles.tip}>或贷款中介咨询机构提供</div>
        <div className={styles.tip}>平台展示的贷款产品年利率范围18%-36%</div>
        <div className={styles.tip}>具体利率以第三方实际放款结果为准</div>
        <div className={styles.tip}>您最终获取的贷款额度、利率、放款时间</div>
        <div className={styles.tip}>以第三方机构实际审批结果为准</div>
        <div className={styles.tip}>温馨提示:请根据个人能力合理贷款，理性消费，避免逾期</div>
        <div className={styles.tip}>请不要相信任何要求您支付费用的信息、邮件、电话等不实信息</div>
        <div className={styles.tip}>
        粤ICP备2023009975号-1 | 深圳聚客融科技有限公司
        </div>
        <div className={styles.tip}>客服电话：400-811-6568</div>
      </div>
      <div className={classNames(styles.dialogOverlay, showPopup && styles.showPopup)}>
        <div className={styles.dialogMain}>
          <div className={styles.close} onClick={() => setShowPopup('')}>X</div>
          <div className={styles.inputBox}>
            <NumberInput
              placeholder="请输入验证码"
              value={code}
              numberFormat={{
                decimal: false,
                negative: false,
                maxLength: 6
              }}
              onChange={val => {
                setCode(val)
              }}
            />
            {
              time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                : <span className={styles.code} onClick={getCode}>获取验证码</span>
            }
          </div>
          <div className={styles.getQuota} onClick={register}>获取额度</div>
        </div>
      </div>
    </div>
  )
}

export default Skin6
