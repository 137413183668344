/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './rematch-waiting.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { ENTRANCE_TYPE } from '@/constants/common'
import { isIOS, isInWeChat, isMobile, download } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'

interface Props{}

const WaitingSuccess:React.FC<Props> = () => {
  const [search] = useSearchParams()
  const options:any = search.get('options')
  let resData:any = {}
  if (options) {
    try {
      resData = JSON.parse(decodeURIComponent(options))
    } catch {
      resData = JSON.parse(options)
    }
  }
  const adChannelCode = localStorage.getItem('ad_channel_code')
  const [isMjy, setIsMjy] = useState(false)

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(3)
  const timer = useRef<ReturnType<typeof setTimeout>>()
  let mjyCode = ['PD4Amz', '25WbiH']

  // 倒计时
  useEffect(() => {
    if (mjyCode.includes(adChannelCode!)) {
      setIsMjy(true)
    }
    if (countdownTime > 0) {
      timer.current = setTimeout(() => {
        setCountdownTime(countdownTime - 1)
      }, 1000)
    } else {
      // 惠逸花授权页
      if (resData.rqResult.thirdpartyType === 5) {
        window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(resData.rqResult))}&adChannelCode=${resData.adChannelCode}&isHuaWeiPhone=${resData.isHuaWeiPhone}&applyLimit=${resData.applyLimit}`)
        return
      }
      console.log(resData)
      if (adChannelCode === '9o0w2B') {
        window.location.replace(`/new-fill-information?options=${encodeURIComponent(JSON.stringify(resData))}`)
        return
      }
      // 公用授权页
      window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(resData))}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  return (
    <section className={styles.authorizePage}>
      <Header title={isMjy ? '极速借贷' : '聚客融'} />
      <img className={styles.rematchGif} src={require('@imgs/gif/rematch.gif')} />
    </section>
  )
}

export default WaitingSuccess