import { useState, useEffect } from 'react'
import yuanxinLogo from '@imgs/yuanxin-logo.png'
import youqianlaiLogo from '@imgs/youqianqianbao-logo.png'
import youqianlaiText from '@imgs/youqianqianbao-text.png'

interface hostNameObjType {
    appName: string,
    companyName: string,
    filing:string,
    logo: string,
    appType: number,
    logoText: string
  }

// 获取域名
function useGetHostnameObj() {
  const [hostname, setHostname] = useState('')

  useEffect(() => {
    setHostname(window.location.hostname)
  }, [])

  const defaultObj:hostNameObjType = {
    appName: '聚客融',
    companyName: '深圳聚客融科技有限公司', 
    filing: '粤ICP备2023009975号-1',
    logo: youqianlaiLogo,
    appType: 1,
    logoText: youqianlaiText,
  }

  // const tempObj:Record<string, hostNameObjType> = {
  //   'h5.yuanxinwallet.com': {
  //     appName: '元信花',
  //     companyName: '深圳聚客融科技有限公司',
  //     filing: '粤ICP备2022120631号-1',
  //     logo: yuanxinLogo,
  //     appType: 1,
  //     logoText: '',
  //   },
  //   'h5.ningkun8.cn': {
  //     appName: '元信钱包',
  //     companyName: '海南柠坤科技有限公司',
  //     filing: '琼ICP备2022018381号-1',
  //     logo: yuanxinLogo,
  //     appType: 2,
  //     logoText: '',
  //   },
  //   'yql-h5.chongdong.cc': defaultObj
  // }

  return defaultObj
}


export default useGetHostnameObj