/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './success.module.scss'
import { isIOS, isInWeChat, isMobile, download, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import IosSkin from './components/ios-skin/ios-skin'
import Fenqile from './components/fenqile/fenqile'
import WhiteListCity from './components/360-white-list-city/360-white-list-city'
import MiniGrogram from './components/mini-program/mini-program'
import NormalSkin from './components/normal-skin/normal-skin'

interface Props{}

const Success:React.FC<Props> = () => {
  const [search] = useSearchParams()
  const navigate = useNavigate()
  const options: any = search.get('options')
  let isMultiProduct = search.get('isMultiProduct') === 'true'
  let resData:any = {}
  if (options) {
    try {
      resData = JSON.parse(decodeURIComponent(options))
    } catch {
      resData = JSON.parse(options)
    }
  }
  const { productName } = resData
  const { thirdpartyApiType } = resData
  const { thirdpartyType } = resData
  const { thirdpartyTargetUrl } = resData
  const { isNotFormReq } = resData
  let { applyStatus } = resData //是否hold单，状态为8代表hold单
  // let { revisit } = resData // 用户已授权状态下，再次访问结果页的标识(非第一回)
  let revisit = true // 暂时去掉再访状态的来源获取，默认为true
  let isProduct = process.env.REACT_APP_API_ENV === 'production'
  const { isSpecialListCity } = resData // 是否来自特殊城市的用户 || 注册-下载模式的用户

  /** rePageShowType 0/null 不处理 1：取消下载需求 2：引导公众号需求 */
  let { rePageShowType } = resData

  // 判断是否百度渠道的分发页
  const isBaiduChannels = localStorage.getItem('isBaiduChannels')
  if (isBaiduChannels) {
    rePageShowType = 2 //如果是的话，展示和分期乐一样的结果页
  }

  const [isMiniProgram, setIsMiniProgram] = useState(false)
  // 渠道详情
  const [codeDetail, setCodeDetail] = useState<any>()
  //isPageSetup 为true时 结果页无只有分发结果，无H5产品和下载
  const [isPageSetup, setIsPageSetup] = useState(false)
  let mjyCode = ['PD4Amz', '25WbiH']
  const [isSkin12, setIsSkin12] = useState<boolean>(false)
  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  // 判断是否是麦吉优由于麦吉优需要特殊处理，匹配结果页不能展示其他任何内容，所以将它的结果页分配到分期乐页面
  const [isMjy, setIsMjy] = useState(false)

  useEffect(() => {
    const getChannelDetail = async() => {
      const codeDetailRes:any = await activityApi.getChannelCodeDetail(adChannelCode)
      setCodeDetail(codeDetailRes)
      if (codeDetailRes.pageSetup) {
        setIsPageSetup(true)
      }
    }
    if (adChannelCode == 'HgUNci') {
      setIsSkin12(true)
    }
    if (adChannelCode == 'thCSGH') {
      setIsSkin12(true)
    }    
    if (adChannelCode) {
      if (mjyCode.includes(adChannelCode)) {
        setIsMjy(true)
      }
      getChannelDetail()
    }
  }, [adChannelCode])

  // app类型 1-元信花yuanxinwallet.com、2-有钱来ningkun8.cn
  const appType = useMemo(() => {
    return 1
  }, [])

  // 安卓下载链接
  const [androidUrl, setAndroidUrl] = useState('')
  // ios下载链接
  const [iosUrl, setIosUrl] = useState('')

  useEffect(() => {
    // 获取平台来源
    const platFormSource = sessionStorage.getItem('FLATFORM_SOURCE')
    if (platFormSource === 'MiniProgram') {
      setIsMiniProgram(true)
    }

    // 获取最新一次存储的渠道码
    const lastAdChannelCode = localStorage.getItem('ad_channel_code')
    if (lastAdChannelCode) {
      setAdChannelCode(lastAdChannelCode)
    }

    // 获取app下载url
    async function getAppDownloadUrl() {
      try {
        const res = await activityApi.getNewestVersion({
          appType
        })
        setAndroidUrl(res.androidPackageLocation)
        setIosUrl(res.iosPackageLocation)
      } catch (error) {
        // Toast.show({
        //   content: JSON.stringify(error),
        //   duration: 3000
        // })
      }
    }
    getAppDownloadUrl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if ((thirdpartyType === 4 || thirdpartyType === 10) && thirdpartyTargetUrl) {
      jumpOtherUrl()
      return
    }

    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      } else if (rePageShowType !== 2 && rePageShowType !== 1 && !isIOS && !revisit) {
        downloadApp()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  // 跳转第三方
  const jumpOtherUrl = () => {
    if ((thirdpartyType === 4 || thirdpartyType === 10) && thirdpartyTargetUrl) {
      // 跳转第三方链接
      window.location.href = thirdpartyTargetUrl
    }
  }

  // 下载文件
  const downloadApp = async() => {
    // 判断是否为海尔消金
    let isHR = adChannelCode === 'r97gwM'
    // 判断是否为拍拍贷
    let ppdCode = ['rWMspl', 'ZgvvGJ', 'pmeLeA', 't4xtSe']
    if (isPageSetup) {
      return
    }

    // 上报用户下载APP
    try {
      await activityApi.reportUserDownloadApp()
    } catch (error) {
      // console.log('error')
    }
    if (isIOS) {
      // download('http://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/app_download/%E6%96%B0%E7%89%88%E5%85%83%E4%BF%A1%E9%92%B1%E5%8C%85.ipa', '元信', 'ipa')
      // 跳转IOS应用商店
      // 如果是拍拍贷则跳转到聚客融appstore
      window.location.href = iosUrl
    } else {
      if (isHR) {
        window?.WebViewJavascriptBridge && window.WebViewJavascriptBridge?.callHandler('openExternalBrowser', { jumpUrl: androidUrl }, (res:any) => {
          Toast.show({
            content: '跳转成功',
            duration: 3000
          })
        })
      }
      // 让拍拍贷渠道单独下载指定的安卓聚客融
      if (ppdCode.indexOf(adChannelCode) !== -1) {
        download('https://static.chongdongXX.cc/android_package/youqianqiaobao-h5Release-v1.0.9_9-20240530.apk', '聚客融', 'apk')
        return
      }
      download(androidUrl, '元信', 'apk')
    }
  }

  const copyDownLoadUrl = () => {
    Toast.show('链接已复制，快去浏览器打开下载吧!')

    if (isIOS) {
      copy(iosUrl)
    } else {
      copy(androidUrl)
    }
  }


  const miniComponent = () => {
    return (
      <MiniGrogram productName={productName} applyStatus={applyStatus} onChange={copyDownLoadUrl} isSkin12={isSkin12}></MiniGrogram>
    )
  }

  useEffect(() => {
    let mjyAdchannel = mjyCode.includes(localStorage.getItem('ad_channel_code')!)
    const handlePopState = () => {
      if (isProduct) {
        // Retain参数代表是从本success页面跳转到挽留页面
        // window.location.href = 'https://yql-h5.chongdong.cc/advertising?retain=1'
        window.location.href = 'https://jkr-h5.jukerong.cn/advertising?retain=1'
      } else {
        // window.location.href = 'https://qa.oss.h5.yuanxinwallet.com/advertising?retain=1'
        // ????? XXXXXXXXxx
        window.location.href = 'https://jkr-h5.jukerong.cn/advertising?retain=1'
      }
    }
    // 监听popstate事件
    if (!mjyAdchannel) {
      window.addEventListener('popstate', handlePopState)
    }
    // 初始加载时添加一个历史条目
    window.history.pushState(null, '', document.URL)
    console.log(isMultiProduct)

    // 清理事件监听器
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const otherResultComponent = () => {
    return (
      <div>
        {
          !isMjy && (rePageShowType === 1 || (rePageShowType !== 2 && !isIOS && applyStatus !== 8 && !revisit && !isPageSetup && !isSpecialListCity)) ? <>
            <NormalSkin
              productName={productName}
              rePageShowType={rePageShowType}
              countdownTime={countdownTime}
              onChange={downloadApp}
              isMultiProduct={isMultiProduct}
              isSkin12={isSkin12}
            ></NormalSkin>
          </> : <>
            {
              rePageShowType === 2 || (isPageSetup && !isSpecialListCity) || isMjy ? <>
                <Fenqile isMultiProduct={isMultiProduct} productName={productName} applyStatus={applyStatus} isSkin12={isSkin12}></Fenqile>
              </> : <>
                {
                  isSpecialListCity ? <>
                    <WhiteListCity onChange={downloadApp} isSkin12={isSkin12}></WhiteListCity>
                  </> : <>
                    <IosSkin isMultiProduct={isMultiProduct} productName={productName} applyStatus={applyStatus} revisit={revisit} onChange={downloadApp} isSkin12={isSkin12}></IosSkin>
                  </>
                }
              </>
            }
          </>
        }
      </div>
    )
  }

  return (
    <div className={isSkin12 ? styles.skin12 : ''}>
      { isMiniProgram && rePageShowType !== 1 ? miniComponent() : codeDetail && otherResultComponent() }
    </div>
  )
}

declare const window: Window & { WebViewJavascriptBridge: any, WVJBCallbacks: any }

export default Success