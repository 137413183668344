/* eslint-disable no-magic-numbers */
// API转H5产品皮肤
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './institution-list.module.scss'
import { Button, Toast, Popup, Checkbox, Image } from 'antd-mobile'
import classNames from 'classnames'


interface Props {
  rqResult: any,
  onCheckedChange: (id: number, value: boolean) => void
  checkInstitution?: number[] //多产品当前勾选的产品id数组
  isPopupType?:boolean
  isSkin12?:boolean
}

const InstitutionList: React.FC<Props> = ({ rqResult, onCheckedChange, checkInstitution, isPopupType, isSkin12 }) => {

  // 是否显示弹出层
  const [monthVisible, setMonthVisible] = useState(false)
  // 当前选中月份
  // eslint-disable-next-line no-magic-numbers
  const [currentMonth, setCurrentMonth] = useState<number>(12)
  // 判断是否是快手
  const [isKuaishou, setIsKuaishou] = useState(false)
  // 月份数组
  const monthArr = [
    {
      key: 1,
      num: 3,
    },
    {
      key: 2,
      num: 6,
    },
    {
      key: 3,
      num: 9,
    },
    {
      key: 4,
      num: 12,
    },
  ]

  // 选择月份
  const selectMonth = (num: number) => {
    setCurrentMonth(num)
    setMonthVisible(false)
  }

  useEffect(() => {
    const kuaishouCode = ['EQrvFe', 'rYoXK7', 'CG5Gd7']
    console.log(rqResult)
    let adChannelCode = localStorage.getItem('ad_channel_code')


    if (kuaishouCode.includes(adChannelCode!)) {
      setIsKuaishou(true)
    }
  }, [checkInstitution])

  return (
    <div className={styles.institutionList}>
      {
        // 这里添加的逻辑是判断是否为多产品，如果为多产品则展示多产品列表，如果不是就往下走逻辑
        // eslint-disable-next-line no-nested-ternary
        <div className={styles.institutionListBox}>
          <div className={styles.yqlWholeProcessBoxHeader}>
            <div className={styles.yqlWholeProcessBoxHeaderLeft}>
              <div className={styles.welcome}>
                {
                  !isPopupType && <img className={styles.yqlWholeProcessBoxHeaderIcon} src={isSkin12 ? require('@imgs/information/success-skin12.png') : require('@imgs/information/checked2.png')} />
                }
                <div className={styles.yqlWholeProcessBoxHeaderTitle}>恭喜您，贷款匹配成功</div>
              </div>
              {
                isPopupType && <div className={styles.seconedTitle}>
                  <img src={require('@/assets/imgs/form-result/new-success.png')} className={styles.iconSuccess} alt="youqianqianbao-new-success" />
                  <span>严选正规机构100+，为您匹配低息借款方案</span>
                </div>
              }
            </div>
            <div className={styles.yqlWholeProcessBoxHeaderRight}>
              {
                rqResult.mayAuthProductList?.length > 0 && <>
                  <span style={{ marginBottom: '5px' }}>同时申请放款率</span>
                  <div>
                    <img className={styles.unionIcon} src={require('@imgs/information/Union.png')} />
                    <img className={styles.percentageIcon} src={require('@imgs/information/percentage.png')} />
                  </div>
                </>
              }
            </div>
          </div>
          <div className={styles.splitLine}></div>
          <div className={styles.multipleProcessBoxContent}>
            <div className={styles.multipleProcessTitle}>
                贷款服务平台及机构
            </div>
            <div className={styles.multipleProcessContentItem}>
              <div className={styles.multipleProcessContentItemLeft}>
                <div>
                  {
                    (rqResult.thirdpartyType !== 7 && rqResult.thirdpartyType !== 2) || !rqResult.yqlWholeProcessBumpVO?.partnerLogo ? <>
                      <img className={styles.contentItemIcon} src={isSkin12 ? require('@imgs/register/purple-theme/logo-1.png') : require('@imgs/information/loan-platform.png')} />
                    </> : <>
                      <Image className={styles.contentItemIcon} fit="cover" style={{
                        marginRight: '10px',
                        borderRadius: 6
                      }} src={rqResult.yqlWholeProcessBumpVO?.partnerLogo} fallback={<img className={styles.contentItemIcon} src={require('@imgs/information/loan-institutions.png')} />}></Image>
                      {/* <img className={styles.contentItemIcon} src={rqResult.yqlWholeProcessBumpVO?.partnerLogo} /> */}
                    </>
                  }
                </div>
                <div className={styles.multipleProcessContentItemInfo}>
                  <span>
                    { (rqResult.thirdpartyType === 7 || rqResult.thirdpartyType === 2) ? rqResult.productName : '聚客融' }
                  </span>
                  <div>
                    {
                      (rqResult.thirdpartyType === 7 || rqResult.thirdpartyType === 2)
                        ? <>{rqResult.yqlWholeProcessBumpVO?.partnerProductName ?? ''}{isKuaishou ? ''
                          : `(${rqResult.yqlWholeProcessBumpVO?.partnerCompanyName ?? ''})`}</>
                        : <>{rqResult.productName ?? ''}{isKuaishou ? '' : `(${rqResult.productCompany ?? ''})`}</>
                    }
                  </div>
                </div>
              </div>
              <div className={styles.multipleProcessContentItemRight}>
                <Checkbox checked={true} disabled={true} className={styles.checkBoxOne}></Checkbox>
              </div>
            </div>
            {
              rqResult.mayAuthProductList?.length > 0 && rqResult.mayAuthProductList.map((product: any) => {
                return <div key={product.id} className={styles.multipleProcessContentItem}>
                  <div className={styles.multipleProcessContentItemLeft}>
                    <div>
                      {
                        (product.thirdpartyType !== 7 && product.thirdpartyType !== 2) || !product.partnerLogo ? <>
                          <img className={styles.contentItemIcon} src={require('@imgs/youqianqianbao-logo.png')} />
                        </> : <>
                          <Image className={styles.contentItemIcon} fit="cover" style={{
                            marginRight: '10px',
                            borderRadius: 6
                          }} src={product.partnerLogo} fallback={<img className={styles.contentItemIcon} src={require('@imgs/information/loan-institutions.png')} />}></Image>
                        </>
                      }
                    </div>
                    <div className={styles.multipleProcessContentItemInfo}>
                      <span>
                        { (product.thirdpartyType === 7 || product.thirdpartyType === 2) ? product.productName : '聚客融' }
                      </span>
                      <div>
                        { (product.thirdpartyType === 7 || product.thirdpartyType === 2)
                          ? <>{`${product.partnerProductName ?? ''}${isKuaishou ? '' : `(${product.partnerCompanyName})`}`}</>
                          : <>{product.productName}{isKuaishou ? '' : `(${product.productCompany})`}</>}
                      </div>
                    </div>
                  </div>
                  <div className={styles.multipleProcessContentItemRight}>
                    <Checkbox checked={checkInstitution?.includes(product.id)} onChange={e => onCheckedChange(product.id, e)} className={styles.checkBoxOne}></Checkbox>
                  </div>
                </div>
              })
            }
          </div>
        </div>
      }
      <Popup
        visible={monthVisible}
        onMaskClick={() => {
          setMonthVisible(false)
        }}
      >
        <ul className={styles.monthBox}>
          {
            monthArr.map(item => {
              return <li
                key={item.key}
                className={classNames(styles.row, item.num === currentMonth && styles.active)}
                onClick={() => selectMonth(item.num)}
              >
                {item.num} 个月
              </li>
            })
          }
        </ul>
      </Popup>
    </div>
  )
}

export default InstitutionList
